.App {
  text-align: center;
}

.logo-left {
  height: 40vmin;
  pointer-events: none;
  vertical-align: middle;
  margin: 0 -1.2em 0 .2em;
  zoom: 0.6;
  background:-webkit-linear-gradient(#eee, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: logo-left infinite 1.5s;

}
.logo-right {
  height: 40vmin;
  pointer-events: none;
  vertical-align: middle;
  margin: 0 .2em 0 -1.2em;
  zoom: 0.6;
  background:-webkit-linear-gradient(#eee, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: logo-right infinite 1.5s;

}


@font-face {
  font-family: 'BenguiatGothicBold';
  src: url('../public/fonts/BenguiatGothicBold.otf');
  font-weight: normal;
  font-style: normal;
}

/*
#d72323
#eeeeee
#e24b4b
#ab1c1c
#ffffff
#d4d4d4
 */

.logo{
  background:-webkit-linear-gradient(#eee, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  letter-spacing: 5px;
  zoom: 0.6;

}
.App-header {

  color:#d72323;
  min-height: 100vh;
  display: inline-block;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16vmin;

  font-family:'BenguiatGothicBold';

}

.App-link {
  color: #61dafb;
}

@keyframes logo-left {
  0%,100% {
     transform: skew(0deg, 0deg);
  }
  50% {
    transform: skew(0deg, 10deg);
  }
}

@keyframes logo-right {
  0%,100% {
    transform: skew(0deg, 0deg);
  }
  50% {
    transform: skew(0deg, -10deg);
  }
}



body{

  background: -webkit-linear-gradient(#e24b4b, #ab1c1c);
/*-webkit-linear-gradient(#393737, #050505)*/
  padding-top: 16em;
}

